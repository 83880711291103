var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment_item_cod",on:{"click":function($event){$event.stopPropagation();return _vm.clickItemCod.apply(null, arguments)}}},[_c('div',{staticClass:"mshe-flexbetween"},[_c('s-checkbox',{staticClass:"payment-item-checkbox",attrs:{"value":_vm.cardValue,"disabled":_vm.item.enabled == 0,"name":"paymentmethod","data-id":_vm.item.id,"label":_vm.item.code,"margin-r":`${16 / 75}rem`}}),_vm._v(" "),_c('div',{staticClass:"card-left",class:{'ssr-se': !_vm.isClient }},[_c('img',{staticClass:"payment-src",attrs:{"src":_vm.transformImg({ img: _vm.getPaymentLogoUrl })}}),_vm._v(" "),_c('span',{staticClass:"check-list",class:{
          'check-disabled': _vm.item.enabled == 0 || _vm.notSupportCodReason
        }},[_c('span',{staticClass:"check-title"},[_vm._v(_vm._s(_vm.getPaymentTitle))])]),_vm._v(" "),_c('CodTips',{attrs:{"language":_vm.language,"logo-url":_vm.item.logo_url},on:{"codTipExpose":_vm.codTipExpose,"clickCodTips":_vm.clickCodTips}}),_vm._v(" "),(!(!_vm.mall_caculate_info.isSupportCod || _vm.notSupportCodReason))?_c('span',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.status.cardPay == _vm.item.code &&
            (_vm.lang == 'ar' || _vm.lang == 'ar-en') &&
            _vm.mall_caculate_info.codPrice &&
            _vm.mall_caculate_info.codPrice.amount > 0
        ),expression:"\n          status.cardPay == item.code &&\n            (lang == 'ar' || lang == 'ar-en') &&\n            mall_caculate_info.codPrice &&\n            mall_caculate_info.codPrice.amount > 0\n        "}],staticClass:"off-tips cod-tips-wid"},[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_15143)+"\n        "+_vm._s(_vm.mall_caculate_info.codPrice.amountWithSymbol)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.isShowZeroDiscount)?_c('s-label',{staticClass:"payment-discount-zero",attrs:{"type":"promo"}},[(_vm.onlinePayDiscountInfoValue.discountType == 0)?[(_vm.GB_cssRight)?[_vm._v("\n            "+_vm._s(_vm.language.SHEIN_KEY_PWA_14988 +
                ' ' +
                _vm.onlinePayDiscountInfoValue.amount.amountWithSymbol)+"\n          ")]:[_vm._v("\n            "+_vm._s(_vm.onlinePayDiscountInfoValue.amount.amountWithSymbol +
                ' ' +
                _vm.language.SHEIN_KEY_PWA_14988)+"\n          ")]]:(_vm.onlinePayDiscountInfoValue.discountType == 1)?[(_vm.GB_cssRight)?[_vm._v("\n            "+_vm._s(_vm.language.SHEIN_KEY_PWA_14988 +
                ' ' +
                _vm.onlinePayDiscountInfoValue.percent +
                '%')+"\n          ")]:[_vm._v("\n            "+_vm._s(_vm.onlinePayDiscountInfoValue.percent +
                '% ' +
                _vm.language.SHEIN_KEY_PWA_14988)+"\n          ")]]:_vm._e()],2):_vm._e()],1)],1),_vm._v(" "),(_vm.paymentDes && _vm.item.enabled != 1)?_c('PaymentCampTxt',{attrs:{"collapse":_vm.txtIsCamp,"txt":_vm.paymentDes},on:{"update:collapse":function($event){_vm.txtIsCamp=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }