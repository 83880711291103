<template>
  <div
    class="payment_item_cod"
    @click.stop="clickItemCod"
  >
    <div class="mshe-flexbetween">
      <s-checkbox
        class="payment-item-checkbox"
        :value="cardValue"
        :disabled="item.enabled == 0"
        name="paymentmethod"
        :data-id="item.id"
        :label="item.code"
        :margin-r="`${16 / 75}rem`"
      />
      <div
        class="card-left"
        :class="{'ssr-se': !isClient }"
      >
        <img
          class="payment-src"
          :src="transformImg({ img: getPaymentLogoUrl })"
        />
        <span
          class="check-list"
          :class="{
            'check-disabled': item.enabled == 0 || notSupportCodReason
          }"
        >
          <span class="check-title">{{ getPaymentTitle }}</span>
        </span>
        <CodTips
          :language="language"
          :logo-url="item.logo_url"
          @codTipExpose="codTipExpose"
          @clickCodTips="clickCodTips"
        />
        <span
          v-if="!(!mall_caculate_info.isSupportCod || notSupportCodReason)"
          v-show="
            status.cardPay == item.code &&
              (lang == 'ar' || lang == 'ar-en') &&
              mall_caculate_info.codPrice &&
              mall_caculate_info.codPrice.amount > 0
          "
          class="off-tips cod-tips-wid"
        >
          {{ language.SHEIN_KEY_PWA_15143 }}
          {{ mall_caculate_info.codPrice.amountWithSymbol }}
        </span>

        <!-- 0元门槛优惠 -->
        <s-label
          v-if="isShowZeroDiscount"
          type="promo"
          class="payment-discount-zero"
        >
          <template v-if="onlinePayDiscountInfoValue.discountType == 0">
            <template v-if="GB_cssRight">
              {{
                language.SHEIN_KEY_PWA_14988 +
                  ' ' +
                  onlinePayDiscountInfoValue.amount.amountWithSymbol
              }}
            </template>
            <template v-else>
              {{
                onlinePayDiscountInfoValue.amount.amountWithSymbol +
                  ' ' +
                  language.SHEIN_KEY_PWA_14988
              }}
            </template>
          </template>

          <template v-else-if="onlinePayDiscountInfoValue.discountType == 1">
            <template v-if="GB_cssRight">
              {{
                language.SHEIN_KEY_PWA_14988 +
                  ' ' +
                  onlinePayDiscountInfoValue.percent +
                  '%'
              }}
            </template>
            <template v-else>
              {{
                onlinePayDiscountInfoValue.percent +
                  '% ' +
                  language.SHEIN_KEY_PWA_14988
              }}
            </template>
          </template>
        </s-label>
      </div>
    </div>
    <PaymentCampTxt 
      v-if="paymentDes && item.enabled != 1"
      :collapse.sync="txtIsCamp"
      :txt="paymentDes"
    />
  </div>
</template>

<script>
import { transformImg } from '@shein/common-function'
import PaymentCampTxt from 'public/src/pages/checkout/vue_tpls/camp_txt/camp_txt.vue'
import CodTips from 'public/src/pages/checkout/vue_tpls/cod_tips.vue'
const { GB_cssRight, lang } = gbCommonInfo
export default {
  name: 'PaymentOptionsItemCod',
  components: {
    PaymentCampTxt,
    CodTips
  },
  emits: [ 'handleStartSelectBank', 'clickItemCod', 'codTipExpose', 'clickCodTips'],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    status: {
      type: Object,
      default: () => ({})
    },
    isDisabledPayment: {
      type: Boolean,
      default: false
    },
    cardValue: {
      type: Boolean,
      default: false
    },
    paymentDisplay: {
      type: Boolean,
      default: true
    },
    paymentDes: {
      type: String,
      default: ''
    },
    paymentIconPrefix: {
      type: String,
      default: ''
    },
    // eslint-disable-next-line vue/prop-name-casing
    mall_caculate_info: {
      type: Object,
      default: () => ({})
    },
    notSupportCodReason: {
      type: String,
      default: ''
    },
    onlinePayDiscountInfoValue: {
      type: Object,
      default: () => ({})
    },
    banksConfig: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    getPaymentLogoUrl: {
      type: String,
      default: ''
    },
    isClient: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      GB_cssRight,
      lang,
      txtIsCamp: false
    }
  },
  computed: {
    campIconName() {
      return this.txtIsCamp ? 'sui_icon_more_down_16px_2' : 'sui_icon_more_up_16px_2'
    },
    itemCode() {
      return this.item.code
    },
    getPaymentTitle() {
      return this.item.title
    },
    isShowZeroDiscount() {
      return this.item.enabled && this.onlinePayDiscountInfoValue && [0, 1].includes(this.onlinePayDiscountInfoValue?.discountType)
    }
  },
  methods: {
    transformImg,

    handleStartSelectBank() {
      this.$emit('handleStartSelectBank')
    },
    clickItemCod() {
      if(this.isDisabledPayment){
        this.txtIsCamp = !this.txtIsCamp
      }else{
        this.$emit('clickItemCod')
      }
    },
    codTipExpose() {
      this.$emit('codTipExpose')
    },
    clickCodTips() {
      this.$emit('clickCodTips')
    }
  }
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.payment_item_cod {
  display: flex;
  flex-direction: column;
  /deep/ .payment_item_cod_tips {
    .payment_item_cod_tips__txt {
      color: #999;
    }
  }
}
</style>
