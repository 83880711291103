import { computed, ref, watch } from 'vue'
import { template } from '@shein/common-function'
import { useStore, useMapMutations } from 'public/src/pages/checkout/hooks/index.js'

const useTokenBinDiscount = (cardBinDiscountInfo, cardBin, language) => {
  const allCardBinDiscountMap = computed(() => {
    const { all_card_bin_discount_map = {} } = cardBinDiscountInfo
    return all_card_bin_discount_map
  })

  const type = computed(() => {
    const { type = 0 } =
      allCardBinDiscountMap.value?.[cardBin] || {}

    return type
  })

  const isTokenBinRandomType = computed(() => {
    return type.value === 3
  })

  const getTokenRandomBinDiscountDesc = () => {
    const {
      max_random_amount = {},
      min_random_amount = {},
      min_amount = {},
      issuer_bank_name = ''
    } = allCardBinDiscountMap.value?.[cardBin] || {}

    if (min_amount?.amount > 0) {
      if (issuer_bank_name?.length > 0) {
        return template(
          min_random_amount?.amountWithSymbol,
          max_random_amount?.amountWithSymbol,
          min_amount?.amountWithSymbol,
          issuer_bank_name,
          language.SHEIN_KEY_PWA_28691 || ''
        )
      }

      return template(
        min_random_amount?.amountWithSymbol,
        max_random_amount?.amountWithSymbol,
        min_amount?.amountWithSymbol,
        language.SHEIN_KEY_PWA_24937 || ''
      )
    }

    if (issuer_bank_name?.length > 0) {
      return template(
        min_random_amount?.amountWithSymbol,
        max_random_amount?.amountWithSymbol,
        issuer_bank_name,
        language.SHEIN_KEY_PWA_28692 || ''
      )
    }

    return template(
      min_random_amount?.amountWithSymbol,
      max_random_amount?.amountWithSymbol,
      language.SHEIN_KEY_PWA_24866 || ''
    )
  }

  const getCardBinDiscountDesc = () => {
    const { total_discount_amount, type = 0 } =
      allCardBinDiscountMap.value?.[cardBin] || {}

    // 优惠类型 1：满减 2：折扣 3 随机立减
    if ([1, 2].includes(type) && total_discount_amount?.amount > 0) {
      return template(
        total_discount_amount?.amountWithSymbol,
        language.SHEIN_KEY_PWA_24493 || ''
      )
    }

    if (type === 3) {
      return getTokenRandomBinDiscountDesc()
    }
  }

  return {
    getCardBinDiscountDesc,
    type,
    isTokenBinRandomType
  }
}

const useIsShowBinDiscountInfo = ({ language = {} }) => {
  const store = useStore()
  const tokenBinDiscountDesc = ref('')
  const isNowTokenBinRandomType = ref(false)
  const { assignState } = useMapMutations(['assignState'])

  const checkout = computed(() => {
    return store?.state?.checkout?.checkout
  })

  const paymentMethods = computed(() => {
    return checkout.value?.results?.paymentMethods || []
  })

  const status = computed(() => {
    return store?.state?.checkout?.status
  })

  const payment = computed(() => {
    return paymentMethods.value?.find(item => item?.code === status.value?.cardPay)
  })

  const handlerSelectBin = computed(() => {
    return store?.state?.checkout?.handlerSelectBin
  })

  const defaultSelectTokenPay = computed(() => {
    return store?.state?.checkout?.defaultSelectTokenPay
  })

  const cardBinDiscountInfo = computed(() => {
    return store?.state?.checkout?.cardBinDiscountInfo
  })

  const installmentSelectBin = computed(() => {
    return store?.state?.checkout?.installmentSelectBin
  })

  const tokenBinDiscountFrontWebAbt = computed(() => {
    return checkout.value?.abtInfo?.tokenBinDiscountFrontWeb?.param?.ableWeb == 1
  })

  const isShowTokenPaymentByAbt = computed(() => {
    const { webshowTokenFront = {} } = checkout.value?.abtInfo || {}
    return webshowTokenFront?.param?.webshowTokenFrontStatus === '1'  // 1: 展示token前置功能
  })

  const isShowTokenPayment = computed(() => {
    // 后端字段是否展示 token 支付
    const isShowTokenPaymentByBackEnd = payment.value?.code === 'routepay-card' && payment.value?.shieldAddCard === 0 // 0代表b不屏蔽，则走abt的判断逻辑
    // 若token数据为空，则判断不符合token前置的场景，则无新增逻辑
    return isShowTokenPaymentByBackEnd && isShowTokenPaymentByAbt.value && (payment.value?.card_token_list || [])?.length > 0 
  })

  const isShowTokenFrontInstallmentAbt = computed(() => {
    const { showTokenFrontInstallment = {} } = checkout.value?.abtInfo || {}
    return showTokenFrontInstallment?.param?.showTokenFrontInstallmentStatus === '1'   
  })

  const isShowTokenFrontInstallment = computed(() => {
    // 后端字段是否展示 token 支付
    const isShowTokenPaymentByBackEnd = payment.value?.code === 'routepay-cardinstallment'

    // 若token数据为空，则判断不符合token前置的场景，则无新增逻辑
    return isShowTokenPaymentByBackEnd && isShowTokenFrontInstallmentAbt.value && (payment.value?.card_token_list || [])?.length > 0 
  })

  const nowShowTokenPayment = computed(() => {
    if (!isShowTokenPayment.value) return {}
    if (!handlerSelectBin.value && Object.keys(defaultSelectTokenPay.value || {})?.length > 0) {
      return defaultSelectTokenPay.value
    }
    return (payment.value?.card_token_list || [])?.find(item => item.id === defaultSelectTokenPay.value?.id)
  })

  const nowInstallmentTokenPayment = computed(() => {
    if (!isShowTokenFrontInstallment.value || !payment.value?.card_token_list) return {}
    return (payment.value?.card_token_list || [])?.find(item => item.card_bin === installmentSelectBin.value)
  })

  const isShowTokenBinDiscount = computed(() => {
    if (!tokenBinDiscountFrontWebAbt.value) {
      return false
    }

    if (isShowTokenPayment.value && nowShowTokenPayment.value) {
      const { all_card_bin_discount_map = {} } = cardBinDiscountInfo.value
      return (nowShowTokenPayment.value?.card_bin in all_card_bin_discount_map)
    }

    if (isShowTokenFrontInstallment.value && nowInstallmentTokenPayment.value) {
      const { all_card_bin_discount_map = {} } = cardBinDiscountInfo.value
      return (nowInstallmentTokenPayment.value?.card_bin in all_card_bin_discount_map)
    }

    return false
  })

  const isShowTokenBinDiscountBlock = computed(() => {
    return isShowTokenBinDiscount.value && tokenBinDiscountDesc.value?.length > 0
  })


  watch(() => isShowTokenBinDiscount.value, (val) => {
    if (typeof window == 'undefined') return false
    if (val) {
      const cardBin = (payment.value?.code === 'routepay-card') ? nowShowTokenPayment.value?.card_bin : installmentSelectBin.value
      const { getCardBinDiscountDesc, isTokenBinRandomType } = useTokenBinDiscount(cardBinDiscountInfo.value, cardBin, language)
      tokenBinDiscountDesc.value = getCardBinDiscountDesc()
      isNowTokenBinRandomType.value = isTokenBinRandomType
    }
  }, {
    immediate: true
  })

  watch(() => nowShowTokenPayment.value?.card_bin, (val) => {
    if (typeof window == 'undefined') return false
    if (val) {
      const { getCardBinDiscountDesc, isTokenBinRandomType } = useTokenBinDiscount(cardBinDiscountInfo.value, val, language)
      tokenBinDiscountDesc.value = getCardBinDiscountDesc()
      isNowTokenBinRandomType.value = isTokenBinRandomType
    }
  })

  watch(() => installmentSelectBin.value, (val) => {
    if (typeof window == 'undefined') return false
    if (val) {
      const { getCardBinDiscountDesc, isTokenBinRandomType } = useTokenBinDiscount(cardBinDiscountInfo.value, val, language)
      tokenBinDiscountDesc.value = getCardBinDiscountDesc()
      isNowTokenBinRandomType.value = isTokenBinRandomType
    }
  })

  watch(() => isShowTokenBinDiscountBlock.value, (val) => {
    if (typeof window == 'undefined') return false
    if (['routepay-card', 'routepay-cardinstallment'].includes(payment.value?.code)) {
      assignState({
        isShowTokenBinDiscountBlock: val
      })
    }
  }, {
    immediate: true
  })

  watch(() => tokenBinDiscountDesc.value, (val) => {
    if (typeof window == 'undefined') return false
    if (['routepay-card', 'routepay-cardinstallment'].includes(payment.value?.code)) {
      assignState({
        tokenBinDiscountDesc: val
      })
    }
  }, {
    immediate: true
  })

  return {
    isShowTokenBinDiscount,
    isShowTokenBinDiscountBlock,
    tokenBinDiscountDesc,
    isShowTokenFrontInstallment,
    isNowTokenBinRandomType
  }
}

export { 
  useTokenBinDiscount,
  useIsShowBinDiscountInfo 
}
