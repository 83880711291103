export default {
  methods: {
    showPriceLimit: function (code) {

      let txt = ''
      if (this.mall_caculate_info && 
			this.mall_caculate_info.paymentMethodAmountLimitInfo && 
			this.mall_caculate_info.paymentMethodAmountLimitInfo[code]
      ) {
        const item = this.mall_caculate_info.paymentMethodAmountLimitInfo[code]
        const { maxAmount, minAmount } = item 
        if (minAmount && maxAmount) {
          txt = this.template(minAmount, maxAmount, this.language.SHEIN_KEY_PWA_16733)
        } else if (!minAmount && maxAmount) {
          txt = this.template(maxAmount, this.language.SHEIN_KEY_PWA_16753)
        } else if (minAmount && !maxAmount) {
          txt = this.template(minAmount, this.language.SHEIN_KEY_PWA_16734)
        }
      }
      txt = txt.replace(/\s/g, ' ')
      return txt
    },
    // 置灰文案
    getPaymentDes (item) {
      let des = ''
      // 如果支付可用
      if (item.show_description && item.enabled == 1 && item.description) {
        des = item.description 

        // 如果支付不可用，或者cod不可用时
      } else if (item.enabled != 1) {
        // 按优先级展示置灰原因，越往下，优先级越高

        // 业务配置置灰文案
        des = item.gray_description || des

        // 金额限制
        if (this.paymentMethodLimitInfo[item.code] == 0) {
          des = this.showPriceLimit(item.code)
        }

        // cod不可用原因
        if (item.code == 'cod') {
          des = this.notSupportCodReason || des
        }
      }

      //如果有店铺限制置灰，优先展示置灰文案
      if( item.isStoreDisabled ){
        des = this.language.SHEIN_KEY_PWA_20331
      }
      
      // 本地采销全量置灰优先级最高
      if (item.isAllBusiness) des = item.gray_description

      if(item.gray_reason == 1){
        //业务配置置灰文案
        des = this.language.SHEIN_KEY_PWA_24508 || ''
      }
      
      // 03 本地自营置灰 => wiki.pageId=1176265937
      if(item.gray_reason == 3){
        //业务配置置灰文案
        des = this.language.SHEIN_KEY_PWA_26043 || 'error 03'
      }
      // 自动续费限制 优先级最低, 若无才有
      if (item.disabled_reason === 'auto_renewal_limit' && !des) {
        des = this.language.SHEIN_KEY_PWA_29741
      }
      return des 
    },
  }
}
