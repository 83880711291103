export function prefetchScripts({ resources }) {
  // 对资源列表进行遍历，并创建对应的 link 元素进行预取
  resources.forEach(resource => {
    const link = document.createElement('link')
    link.setAttribute('rel', 'prefetch')
    link.setAttribute('href', resource)

    if (resource.includes('.png') || resource.includes('.jpg')) {
      link.setAttribute('as', 'image')
    } else if (resource.includes('.js')) {
      link.setAttribute('as', 'script')
    } else if (resource.includes('.css')) {
      link.setAttribute('as', 'style')
    }
    document.head.appendChild(link)
  })
}

// 由于copy过来的get请求参数中有变量比如说new Date()，导致sw缓存需要特殊处理比较麻烦
// 这里模拟SW的StaleWhileRevalidate模式实现
export const storageRequest = async ({ key, params }) => {
  let res = undefined
  try {
    // 有可能在ssr阶段执行，判断一下
    if (typeof window !== 'undefined') {
      const storageString = window?.localStorage?.getItem(key)
      let storageContent = JSON.parse(storageString)
      if (
        typeof storageString === 'string' &&
        storageString?.length > 0 && 
        storageContent
      ) {
        // 有缓存：直接返回缓存结果，异步更新缓存
        res = storageContent
        schttp(params)
          .then(_ => window?.localStorage?.setItem(key, JSON.stringify(_)))
          .catch((e) => {
            console.error('storageRequest 异步更新 Error', e)
          })
      } else {
        // 无缓存：等待接口返回
        try {
          res = await schttp(params)
          window?.localStorage?.setItem(key, JSON.stringify(res))
        } catch (e) {
          console.error('storageRequest 同步更新 Error', e)
        }
      }
    }
  } catch (e) {
    console.error('storageRequest 未使用缓存 Error', e)
    res = undefined
  } finally {
    return res
  }
}


export const payCardPrefetchFn = (() => {
  let pending = false
  let hasFinished = false
  const CARD_PAYMENT_TYPE = [6, 7]  // 表示是卡支付类型，这里写死很不科学，但是目前没有好办法
  const requestUrl = '/ltspc/getAssets/get'
  return async (paymentType) => {
    // 1.非ROWME环境
    // 2.属于卡支付类型
    // 3.未获取到预缓存资源
    // 4.接口不在在pending中
    // 符合上述4个条件才会发起预取请求
    console.time('payCardPrefetchFn')
    console.log('payCardPrefetchFn paymentType', paymentType)
    console.log('payCardPrefetchFn gbCommonInfo?.IS_RW', gbCommonInfo?.IS_RW)
    try {
      if (
        !gbCommonInfo?.IS_RW &&
        CARD_PAYMENT_TYPE?.includes(paymentType) &&
        hasFinished === false &&
        pending === false
      ) {
        pending = true
        let res = await storageRequest({
          key: `pwa_${requestUrl}`,
          params: {
            method: 'GET',
            url: requestUrl,
            params: { _t: +new Date(), site_from: 'pwa' }
          }
        })
        console.log('payCardPrefetchFn res', res)
        if(res?.code == 0 && res?.info?.assets?.length > 0){
          prefetchScripts({ resources: res.info.assets })
          hasFinished = true
        }
      }
    } catch (e) {
      console.error('payCardPrefetchFn Error', e)
    } finally {
      pending = false
      console.timeEnd('payCardPrefetchFn')
    }
  }
})()