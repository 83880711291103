<template>
  <div class="all-privacy">
    <new-privacy
      v-if="isUseNewPaymentSecurity"
      :language="language"
      :logo-list="logoList"
    />
    <privacy-module
      v-else
      :language="language"
      :logo-list="logoList"
    />
  </div>
</template>

<script setup>
import PrivacyModule from './privacy_module.vue'
import NewPrivacy from 'public/src/pages/components/new_privacy.vue'

import { computed } from 'vue'
import { useStore } from 'public/src/pages/checkout/hooks/index.js'

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  }
})

const store = useStore()
const checkout = computed(() => {
  return store?.state?.checkout?.checkout
})

const logoList = computed(() => {
  return store?.state?.checkout?.paymentLogoInfo?.info?.logoList || []
})

const isUseNewPaymentSecurity = computed(() => {
  return (
    checkout.value?.abtInfo?.PaymentSafetyExperiment?.param?.PaymentSecurity ===
    'Type_A'
  )
})
</script>

<style lang="less" scoped>
.all-privacy {
  width: 100%;
  margin-top: 20/75rem;
  padding: 32/75rem 24/75rem 24/75rem 24/75rem;
  background-color: white;
  &:first-child {
    padding-top: 0;
  }
}

/deep/.support-info-title {
  color: @sui_color_safety;
  .font-dpr(28px);
  line-height: 1.21428;
  min-height: 40/75rem;
  .icon-right {
    color: @sui_color_gray_dark3;
  }
  .icon-width {
    width: 32/75rem;
    height: 32/75rem;
    display: inline-block;
    .margin-r(8/75rem);
  }
}
</style>
