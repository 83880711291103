<template>
  <!-- 0元门槛优惠 -->
  <s-label
    v-if="isShowZeroDiscount"
    type="promo"
    class="payment-discount-zero"
  >
    <template v-if="isFullReductionDiscount">
      {{ FullReductionDiscount }}
    </template>

    <template v-else-if="isDiscountOffer">
      {{ discountOffer }}
    </template>
  </s-label>
</template>

<script setup>
import { computed } from 'vue'
import { useGetFirstDiscount } from './useHomogenizationPay.js'

const { GB_cssRight } = gbCommonInfo

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  }
})

const { onlinePayDiscountInfoValue, isShowZeroDiscount } = useGetFirstDiscount({
  language: props.language
})

// 满减优惠
const isFullReductionDiscount = computed(() => {
  return onlinePayDiscountInfoValue.value?.discountType === 0
})

// 折扣优惠
const isDiscountOffer = computed(() => {
  return onlinePayDiscountInfoValue.value?.discountType === 1
})

const FullReductionDiscount = computed(() => {
  if (!isFullReductionDiscount.value) return ''

  if (GB_cssRight) {
    return `${props.language?.SHEIN_KEY_PWA_14988} ${onlinePayDiscountInfoValue.value?.amount?.amountWithSymbol}`
  }

  return `${onlinePayDiscountInfoValue.value?.amount?.amountWithSymbol} ${props.language?.SHEIN_KEY_PWA_14988}`
})

const discountOffer = computed(() => {
  if (!isDiscountOffer.value) return ''
  if (GB_cssRight) {
    return `${props.language?.SHEIN_KEY_PWA_14988} ${onlinePayDiscountInfoValue.value?.percent}%`
  }

  return `${onlinePayDiscountInfoValue.value?.percent}% ${props.language?.SHEIN_KEY_PWA_14988}`
})
</script>

<style lang="less" scoped>
.payment-discount-zero {
  flex-shrink: 0;
}
</style>
