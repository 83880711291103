<template>
  <div class="module-item">
    <div class="support-info-title">
      <span>
        <i class="icon suiiconfont sui_icon_Privacy_16px icon-width"></i>
        {{ language.SHEIN_KEY_PWA_23431 }}
      </span>
    </div>
    <p class="support-info-desc">
      {{ language.SHEIN_KEY_PWA_23432 }}
    </p>
    <div class="payment-logo-list">
      <img
        v-for="(item, i) in logoList"
        :key="`token-${i}`"
        :alt="item.url"
        class="logo-item auto-w lazyload"
        :data-src="item.url"
        :src="goods_img_placeholder"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const { LAZY_IMG = '' } = gbCommonInfo

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  logoList: {
    type: Array,
    default: () => []
  }
})

const goods_img_placeholder = ref(LAZY_IMG)
</script>

<style lang="less" scoped>
.support-info-desc {
  padding-top: 12/75rem;
  .font-dpr(24px);
  line-height: 1.1666666;
  color: @sui_color_gray_dark3;
}

.payment-logo-list {
  .flexbox();
  flex-wrap: wrap;
  margin-top: 12/75rem;
  margin-bottom: -12/75rem;
}
.logo-item {
  height: 64/75rem;
  margin-bottom: 12/75rem;
  margin-right: 12/75rem;
  &.auto-w {
    width: auto;
  }
}
</style>
