<template>
  <div
    class="homogenization-logo-block"
    :class="[
      {
        'homogenization-logo__select': isSelectPay
      }
    ]"
  >
    <div
      class="homogenization-logo-list"
      @click="handlerClick"
    >
      <span
        v-if="isSelectPay"
        class="change-to"
      >
        {{ language.SHEIN_KEY_PWA_30981 }}
      </span>
      <div
        ref="imgListRef"
        class="logo-content"
      >
        <div
          v-for="item in logoPayList"
          :key="item.id"
        >
          <img
            class="pay-logo"
            :src="transformImg({ img: getPaymentLogoUrl(item) })"
          />
        </div>
      </div>

      <first-zero-discount
        v-if="isSelectPay"
        :language="language"
      />

      <Icon
        v-if="isSelectPay"
        name="sui_icon_more_down_16px"
        size="16px"
        style="color: #959595"
        class="select-icon"
      />
    </div>

    <discount-tips
      v-if="isShowHomogenizationDiscount"
      ref="discountTipsRef"
      class="homogenization-discount-tips"
      :checkout="checkout"
      :language="language"
      :price="price"
      :current-pay="nowPay"
      :locals="locals"
      :icon-prefix="iconPrefix"
      :is-homogenization="true"
    />
  </div>
</template>

<script setup>
import { computed, ref, nextTick, watch, onMounted } from 'vue'
import {
  useStore,
  useMapMutations
} from 'public/src/pages/checkout/hooks/index.js'
import { transformImg } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
import FirstZeroDiscount from './first_zero_discount.vue'
import { useGetNotZeroDiscount } from './useHomogenizationPay.js'
import DiscountTips from 'public/src/pages/checkout/page_tpls/footer_payment/discount-tips.vue'
import { daEventCenter } from 'public/src/services/eventCenter'

const props = defineProps({
  isDisabledPayment: {
    type: Boolean,
    default: false
  },
  banksConfig: {
    type: Object,
    default: () => {}
  },
  isSelectPay: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => {}
  }
})

const store = useStore()
const { changeHomogenizationDrawerVisible } = useMapMutations([
  'changeHomogenizationDrawerVisible'
])

const {
  isShowHomogenizationDiscount,
  nowPay,
  checkout,
  price,
  locals,
  iconPrefix
} = useGetNotZeroDiscount({ language: props.language })

const imgListRef = ref(null)

const homogenization = computed(
  () => store?.state?.checkout?.checkout?.results?.homogenization
)

// const getPaymentTitle = computed(() => {
//   return homogenization?.value?.title
// })

const paymentMethods = computed(
  () => store?.state?.checkout?.checkout?.results?.paymentMethods || []
)

const homogenizationPayList = computed(() => {
  return (
    paymentMethods.value?.filter(item =>
      homogenization.value?.sub_cate_code_list?.includes(item.code)
    ) || []
  )
})

const isDefaultPayment = computed(() => {
  return homogenizationPayList.value?.some(item => item.last_payment)
})

const logoPayList = computed(() => {
  return paymentMethods.value?.filter(item =>
    homogenization.value?.sub_cate_code_list?.includes(item.code)
  )
})

watch(
  () => props.isSelectPay,
  () => {
    if (typeof window === 'undefined') return
    handlerNextTick(() => {
      checkOverflow(imgListRef.value)
    })
  },
  {
    immediate: true
  }
)

const getPaymentLogoUrl = item => {
  if (!props.isDisabledPayment) {
    return props.banksConfig?.[item?.code]?.bankLogo || item?.logo_url
  }
  return item.gray_logo_url
}

const handlerClick = () => {
  if (!props.isSelectPay) return
  changeHomogenizationDrawerVisible(true)
}

function checkOverflow(center) {
  // 使用 scrollWidth 和 clientWidth 比较来检查是否有溢出
  if (center.scrollWidth > center.clientWidth) {
    // 如果内容宽度超过元素宽度，添加 .overflow 类
    center.classList.add('overflow')
  } else {
    center.classList.remove('overflow')
  }
}

async function handlerNextTick(cb) {
  await nextTick()
  cb && cb()
}

onMounted(() => {
  handlerNextTick(() => {
    daEventCenter.triggerNotice({
      daId: '1-11-1-229',
      extraData: {
        cate_one_pay_mtd: homogenization.value?.code,
        cate_one_is_default: isDefaultPayment.value ? 1 : 0 // 默认已选中的一级支付方式被曝光上报1，默认不选中的运输方式被曝光上报0。
      }
    })
  })
})
</script>

<style lang="less" scoped>
.homogenization-logo-list {
  display: flex;
  align-items: center;
  margin-top: 12/75rem;
  /* 确保容器区域限制为一行 */
  overflow: hidden;
  position: relative;
  white-space: nowrap;

  .change-to {
    color: #222222;
    font-size: 12px;
    font-family: SF UI Text;
    font-weight: 400;
    display: inline-block;
    width: 60 * 2/75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 4 * 2/75rem;
    flex-shrink: 0;
  }

  .pay-logo {
    margin-right: 8/75rem;
    width: 24 * 2/75rem;
  }

  .logo-content {
    display: inline-flex; /* 使图片水平排列 */
    flex: 1;
    overflow: hidden; /* 隐藏超出部分 */
    position: relative;
  }

  .select-icon {
    flex-shrink: 0;
  }
}

.homogenization-logo__select {
  .homogenization-logo-list {
    .margin-l(64/75rem);
    width: 317 * 2/75rem;

    .overflow::after {
      content: '...';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 24 * 2/75rem; /* 可根据需要调整 */
      background: white;
      text-align: center;
    }

    .payment-discount-zero {
      .margin-r(8/75rem);
    }
  }

  .homogenization-discount-tips {
    .margin-l(64/75rem);
  }
}
.homogenization-discount-tips {
  margin-top: 8/75rem;

  /deep/.discount-tips-item {
    background-color: unset;
    border: unset;
    padding: unset;
  }
}
</style>
