import { computed } from 'vue'
import { useDiscount } from 'public/src/pages/checkout/page_tpls/footer_payment/useDiscount.js'
import {
  useStore,
  useMapGetters
} from 'public/src/pages/checkout/hooks/index.js'

export const useHomogenizationPay = ({ language }) => {
  const store = useStore()

  const homogenization = computed(
    () => store?.state?.checkout?.checkout?.results?.homogenization
  )

  const paymentMethods = computed(
    () => store?.state?.checkout?.checkout?.results?.paymentMethods || []
  )

  const homogenizationPayList = computed(() => {
    return paymentMethods.value?.filter(item =>
      homogenization.value?.sub_cate_code_list?.includes(item.code)
    )
  })

  const status = computed(() => store?.state?.checkout?.status)

  const firstDiscountPayData = computed(() => {
    for (let i = 0; i < homogenizationPayList.value?.length; i++) {
      const pay = homogenizationPayList.value[i]
      if (isSelectPay(pay)) {
        continue
      }
      const { isShowZeroDiscount, onlinePayDiscountInfoValue, isHadDisCount } = useDiscount({
        initialPayment: pay,
        language: language
      })

      if (isShowZeroDiscount.value || isHadDisCount()) {
        return {
          isShowZeroDiscount: isShowZeroDiscount.value,
          onlinePayDiscountInfoValue: onlinePayDiscountInfoValue.value,
          isHadDisCount: isHadDisCount(),
          discountPay: pay,
        }
      }
    }

    return {
      isShowZeroDiscount: false,
      onlinePayDiscountInfoValue: {},
      isHadDisCount: false,
      discountPay: {}
    }
  })

  const isSelectPay = pay => {
    return status.value?.cardPay === pay?.code
  }

  return {
    firstDiscountPayData
  }
}

// 获取 0 门槛优惠
export const useGetFirstDiscount = ({ language }) => {
  const { firstDiscountPayData } = useHomogenizationPay({ language })

  const isShowZeroDiscount = computed(() => {
    return firstDiscountPayData.value?.isShowZeroDiscount
  })

  const onlinePayDiscountInfoValue = computed(() => {
    return firstDiscountPayData.value?.onlinePayDiscountInfoValue
  })

  return {
    onlinePayDiscountInfoValue,
    isShowZeroDiscount
  }
}

// 获取非 0 门槛优惠
export const useGetNotZeroDiscount = ({ language }) => {
  const store = useStore()
  const checkout = computed(() => store?.state?.checkout?.checkout)
  const price = computed(() => store?.state?.checkout?.price)
  const locals = computed(() => store?.state?.checkout?.locals)
  const { iconPrefix } = useMapGetters(['iconPrefix'])
  const { firstDiscountPayData } = useHomogenizationPay({ language })

  const isShowHomogenizationDiscount = computed(() => {
    return firstDiscountPayData.value?.isHadDisCount
  })

  const nowPay = computed(() => {
    return firstDiscountPayData.value?.discountPay
  })

  return {
    isShowHomogenizationDiscount,
    nowPay,
    checkout,
    price,
    locals,
    iconPrefix
  }
}
