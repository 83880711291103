<template>
  <div class="mshe-flexbetween homogenization">
    <s-checkbox
      class="payment-item-checkbox"
      :value="cardValue"
      :disabled="disabled"
      name="paymentmethod"
      :data-id="homogenization.id"
      :label="homogenization.code"
      :margin-r="`${16 / 75}rem`"
    />

    <div class="card-left">
      <div class="left-content">
        <div class="card-data">
          <img
            class="payment-src"
            :src="transformImg({ img: getPaymentLogoUrl })"
          />
          <span class="check-list">
            {{ getPaymentTitle }}
          </span>

          <first-zero-discount :language="language" />
        </div>

        <HomogenizationLogList
          :is-disabled-payment="isDisabledPayment"
          :banks-config="banksConfig"
          :language="language"
        />
      </div>

      <Icon
        name="sui_icon_more_down_16px"
        size="16px"
        style="color: #959595"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'public/src/pages/checkout/hooks/index.js'
import { transformImg } from '@shein/common-function'
import HomogenizationLogList from './homogenization_logo_list.vue'
import { Icon } from '@shein-aidc/icon-vue2'
import FirstZeroDiscount from './first_zero_discount.vue'

const props = defineProps({
  isDisabledPayment: {
    type: Boolean,
    default: false
  },
  banksConfig: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  }
})

const store = useStore()

const homogenization = computed(
  () => store?.state?.checkout?.checkout?.results?.homogenization
)

const status = computed(() => store?.state?.checkout?.status)

const disabled = computed(() => {
  return homogenization?.value?.enabled == 0
})

const getPaymentLogoUrl = computed(() => {
  return homogenization?.value?.logo_url
})

const getPaymentTitle = computed(() => {
  return homogenization?.value?.title
})

const cardValue = computed(() => {
  return !!(
    status.value?.cardPay == homogenization?.value?.code &&
    homogenization?.value?.enabled == 1
  )
})
</script>

<style lang="less" scoped>
.homogenization {
  .left-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
</style>
